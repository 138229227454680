import React, { useEffect, useRef } from 'react';
import { Height } from '../util/ScreenHeight'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { Divider, List, ListItem, Typography } from '@mui/material';
import { getId } from '../util/ID'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack } from '@mui/system';

export default function CLanding(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(100)

    const [showEmpty, setShowEmpty] = React.useState(false);
    const [emptyMsg, setEmptyMsg] = React.useState('');
    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const fetchData = () => {
    }

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <Box style={{ flexDirection: 'column', position: 'absolute', width: '100%', top: Height.header + 10, bottom: fullScreen ? 55 : 0, overflowY: 'scroll' }} sx={{}}>
            <Box sx={{ pl: 2, pr: 2 }}>
                {/* <Typography variant='h5' fontWeight={"bold"}>何謂動能投資法</Typography>
                <Typography variant='body1'>動能投資法是由Andreas F. Clenow所發明</Typography> */}

                <Typography sx={{ mt: 2, textAlign: "center" }} variant='h4' fontWeight={"bold"}>軟體說明</Typography>
                <Typography sx={{ mt: 2 }} variant='body1' >動能投資法是從"賺贏大盤的動能投資法"書本中得到的啟發後，進而針對台灣股市設計的一套投資軟體。下面會針對一些重要表格與操作來說明，若需要更詳盡的解說或原理的解釋，可以直接購買此書獲得解答。</Typography>
                {/* <img src='' style={{ width: "500px" }} /> */}

                <Box
                    component="img"
                    sx={{
                        // height: 233,
                        // width: 350,
                        maxHeight: { xs: '40vh', md: '70vh' },
                        maxWidth: { xs: '40wh', md: '70wh' },
                    }}
                    alt="book"
                    src="book.jpg"
                />

                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>如何決定股票的動能</Typography>
                <Typography variant='body1'>將90日(可調整)股價利用線性回歸畫出直線，此直線的斜率就是動能，
                    直線傾斜角度越大動能亦越大。動能表就是將股票用斜率大到小排序所產生的表格。</Typography>

                <Stack direction={"column"}>
                    <Box
                        component="img"
                        sx={{
                            // height: 233,
                            // width: 350,
                            maxHeight: { xs: '50vh', md: '500px' },
                            maxWidth: { xs: '50wh', md: '500px' },
                        }}
                        alt="book"
                        src="800px-Linear_regression.svg.png"
                    />

                    {/* <img src="800px-Linear_regression.svg.png" style={{ width: "500px" }} /> */}
                    <Typography variant="caption">圖片來源：維基百科。</Typography>
                </Stack>

                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>如何決定要買進那些股票？</Typography>
                <Typography variant='body1'>買進時，最主要是按照動能表的順序，由最大動能股票一路買下來，直到資金用完。但有些額外的條件也需要被滿足。
                    可以在動能排序表上找到這些資訊。</Typography>

                <Stack direction={"column"} spacing={0.2} sx={{ p: 2 }}>
                    {/* <Typography variant='body1'>1. 大盤在100日均線之上，才購買新股票。</Typography> */}
                    <Typography variant='body1'>1. 股價必須在100日均線以上。</Typography>
                    <Typography variant='body1'>2. 股價在90日內沒有發生連續2日跳空幅度加總大於15%。</Typography>
                </Stack>
                <Box
                    component="img"
                    sx={{
                        // height: 233,
                        // width: 350,
                        maxHeight: { xs: '33vh', md: '700px' },
                        maxWidth: { xs: '33wh', md: '700px' },
                    }}
                    alt="book"
                    src="mov_table1.png"
                />
                {/* <img src='mov_table1.png' style={{ width: "550px", objectFit: "contain" }} /> */}
                <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                    <Typography variant='body1'>動能：股票的動能排名。排名越前面動能越大。</Typography>
                    <Typography variant='body1'>股票：股票名稱與股價。點擊可以出現K線圖</Typography>
                    <Typography variant='body1'>類別：股票所在的類別。依據分散風險的概念，不希望大部分的股票在同一類別中。</Typography>
                    <Typography variant='body1'>斜率：將90日(可調整)股價利用線性回歸算出的斜率。</Typography>
                    <Typography variant='body1'>均線：100日均線，若股價沒有大於均線，標示為不符合。</Typography>
                    <Typography variant='body1'>跳空：昨日的最高價與今日的最低價就是跳空的幅度。最近90日若發生連續兩日跳空幅度加總超過15%，標示為不符合。</Typography>
                </Stack>

                <Stack direction={"column"} spacing={1} sx={{ mt: 2 }}>
                    <Typography sx={{}} variant='h5' fontWeight={"bold"}>如何決定要買多少張股票？</Typography>
                    <Typography variant='body1'>決定買入多少的準則就是波動越大的股票買少一點，波動越小的股票就買多一點。可以利用真實波動幅度均值(ATR)來當成波動指標，ATR是某段時間（例如20日）內股價最高/最低的幅度平均可以達到幾元。也就是ATR指標越大，買進股票數量越少，ATR指標越小，買進股票數量越多。</Typography>
                    <Typography variant='body1'>買進股數 = (總資金 x 風險因子) / ATR。</Typography>
                    <Typography variant='body1'>也可以表達成</Typography>
                    <Typography variant='body1'>買進股數 x ATR = 總資金 x 風險因子。</Typography>

                    <Typography variant='body1'>透過公式可以得知，另外一個影響買進的因素是風險因子，風險因子是決定每一檔股票您甘願冒的資金風險有多大，風險因子數字越大，在單一支股票投入的資金越多，買進股票張數也越多，但分散分險的程度越小，
                        風險因子數字越小，買進股票張數越少，分散風險越好。風險因子可以自行定義，無固定的範圍，0.0001，0.1，1都是可以。
                    </Typography>
                    <Typography variant='body1'>動能排序表後半段有包含ATR，買進股數，所需資金，與佔總資金的百分比。若發現每支股票可買的股數太少，可以將風險因子調大。</Typography>
                    {/* <img src='mov_table2.png' style={{ marginTop: 10, width: "600px", objectFit: "contain" }} /> */}

                    <Box
                        component="img"
                        sx={{
                            // height: 233,
                            // width: 350,
                            maxHeight: { xs: '50vh', md: '700px' },
                            maxWidth: { xs: '50wh', md: '700px' },
                        }}
                        alt="book"
                        src="mov_table2.png"
                    />
                </Stack>

                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>動能表有哪些設定？</Typography>
                <Typography variant='body1'>買進時雖然是依照動能表的順序買進，但動能表的產生還是有細節可以設定。其中包含：</Typography>
                <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                    <Typography variant='body1'>類型：使用那些標的去做動能的排名。預設值為所有股票加上ETF。</Typography>
                    <Typography variant='body1'>動能天數：用近幾天去計算動能大小。預設值90日。</Typography>
                    <Typography variant='body1'>市值：只用市值排名前幾名的股票來做動能的排序。市值 = 最近一日的收盤價 x 已發行的股份總數。</Typography>
                    <Typography variant='body1'>均線：預設為100日均線，若股價沒有大於均線，此股票在動能表上標示為不符合。</Typography>
                    <Typography variant='body1'>跳空：預設為15%，如果90日內，股價連續2日跳空幅度的加總大於15%，此股票在動能表上標示為不符合。</Typography>
                    <Typography variant='body1'>股價：依據動能排序後，僅顯示股價小於此設定值的股票。若想要通通顯示可以將其值設定最大。</Typography>
                    <Typography variant='body1'>總資金：目前投入動能投資法的總資金。</Typography>
                    <Typography variant='body1'>風險因子：決定每一檔股票甘願冒總資金的多少百分比去做投資。預設為0.1%</Typography>
                    <Typography variant='body1'>總資金：目前投入動能投資法的總資金。</Typography>
                    <Typography variant='body1'>ATR：計算買入股數時所要知道個股的波動大小，預設為20日。</Typography>
                    {/* <img src="setting.png" style={{ width: "400px" }} /> */}
                    <Box
                        component="img"
                        sx={{
                            // height: 233,
                            // width: 350,
                            maxHeight: { xs: '70vh', md: '650px' },
                            maxWidth: { xs: '60vh', md: '450px' },
                        }}
                        alt="book"
                        src="setting.png"
                    />
                    {/* <img src="setting1.png" style={{ width: "400px" }} /> */}
                    <Box
                        component="img"
                        sx={{
                            // height: 233,
                            // width: 350,
                            maxHeight: { xs: '55vh', md: '400px' },
                            maxWidth: { xs: '55vh', md: '400px' },
                        }}
                        alt="book"
                        src="setting1.png"
                    />
                </Stack>


                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>何時賣出股票？</Typography>
                <Typography variant='body1'>在股票失去動能的時候，即可賣出股票。比方說個股動能排序排名超過50就可以將它賣出。所以如果出現下列的情況，都可以視為賣出訊號：</Typography>
                <Stack direction={"column"} spacing={0.5} sx={{ p: 2 }}>
                    <Typography variant='body1'>1. 動能排序排名超過50（可自行調整）。</Typography>
                    <Typography variant='body1'>2. 股價跌破100日均線</Typography>
                    <Typography variant='body1'>3. 如果90日內，股價連續2日跳空幅度的加總大於15%</Typography>
                </Stack>

                <Typography variant='body1'>在持有庫存裡，可以看到現在最新的動能排序名次與之前購買時的名次，可以很容易知道個股是否已經失去動能，接下來是否要賣出還是持續持有。可以每周選擇一天來做買賣的動作。</Typography>
                {/* <img src="storage.png" style={{ width: "400px" }} /> */}
                <Box
                    component="img"
                    sx={{
                        // height: 233,
                        // width: 350,
                        maxHeight: { xs: '45vh', md: '400px' },
                        maxWidth: { xs: '45vh', md: '400px' },
                    }}
                    alt="book"
                    src="storage.png"
                />


                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>如何將股票加入持有庫存？</Typography>
                <Stack direction={"column"} spacing={0.5} sx={{ p: 2 }}>
                    <Typography variant='body1'>1. 可以從動能表找到想買的股票，按下最後一個欄位"動作"上按鈕即可。</Typography>
                    {/* <img src="buy0.png" style={{ width: "600px" }} /> */}
                    <Box
                        component="img"
                        sx={{
                            // height: 233,
                            // width: 350,
                            maxHeight: { xs: '30vh', md: '700px' },
                            maxWidth: { xs: '20wh', md: '700px' },
                        }}
                        alt="book"
                        src="buy0.png"
                    />

                    {/* <img src="buy.png" style={{ width: "400px" }} /> */}
                    <Box
                        component="img"
                        sx={{
                            // height: 233,
                            // width: 350,
                            maxHeight: { xs: '70vh', md: '800px' },
                            maxWidth: { xs: '50wh', md: '400px' },
                        }}
                        alt="book"
                        src="buy.png"
                    />

                    <Typography variant='body1'>2. 可以從持有庫存的標題右邊有個加號圖示按下</Typography>
                    {/* <img src="buy01.png" style={{ width: "500px" }} /> */}
                    <Box
                        component="img"
                        sx={{
                            // height: 233,
                            // width: 350,
                            maxHeight: { xs: '50vh', md: '500px' },
                            maxWidth: { xs: '50wh', md: '500px' },
                        }}
                        alt="book"
                        src="buy01.png"
                    />
                    <Box
                        component="img"
                        sx={{
                            // height: 233,
                            // width: 350,
                            maxHeight: { xs: '70vh', md: '800px' },
                            maxWidth: { xs: '50wh', md: '400px' },
                        }}
                        alt="book"
                        src="buy1.png"
                    />
                    {/* <img src="buy1.png" style={{ width: "400px" }} /> */}
                </Stack>

                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>為何需要庫存調整？</Typography>
                <Typography variant='body1'>決定買入多少的準則就是波動越大的股票買少一點，波動越小的股票就買多一點。在一段時間之後，個股的波動的程度可能跟買入當時不同，所以需要將波動變大的股票，賣掉一些，將波動變小的股票，多買一點。這樣的調整跟動能變弱而賣出股票不同。調整庫存的動作可以兩周調整一次即可。</Typography>
                {/* <img src="balance.png" style={{ width: "1000px" }} /> */}
                <Box
                    component="img"
                    sx={{
                        // height: 233,
                        // width: 350,
                        maxHeight: { xs: '18vh', md: '1000px' },
                        maxWidth: { xs: '15wh', md: '1000px' },
                    }}
                    alt="book"
                    src="balance.png"
                />

                <Typography variant='body1'>庫存調整表格將需要增加或減少的股票數量與需要的資金都清楚的標示出來，讓使用者可以每兩周更方便進行調整。</Typography>


                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>大盤多空如何判斷？</Typography>
                <Typography variant='body1'>買進準則還有一條是大盤為空頭時，不要買進新股票，需將手上的資金保存下來，等待多頭的來臨。至於多空的判斷依據可以直接使用大盤100日均線，加權指數在100日均線以上即是多頭，反之就是空頭。
                    另外，加權指數產生是由所有的上市的股票，而動能表是上市跟上櫃股票混合一起挑出市值排名前幾大的股票，
                    所以依據動能表所用的股票，生成一種新的指數叫做市值指數，可以更精準的判斷動能股票的多空。

                </Typography>
                {/* <img src="big.png" style={{ width: "500px" }} /> */}
                <Box
                    component="img"
                    sx={{
                        // height: 233,
                        // width: 350,
                        maxHeight: { xs: '55vh', md: '800px' },
                        maxWidth: { xs: '55wh', md: '800px' },
                    }}
                    alt="book"
                    src="big.png"
                />

                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>操作的重點整理</Typography>
                <Stack direction={"column"} spacing={0.5} sx={{ p: 2 }}>
                    <Typography variant='body1'>1. 每周挑選一天（例如：星期三）來進行買賣股票。</Typography>
                    <Typography variant='body1'>2. 要買進新股票前先確定大盤是否為多方。</Typography>
                    <Typography variant='body1'>3. 從動能表中從前面排名先買，買到資金沒有</Typography>
                    <Typography variant='body1'>4. 買進的股票要100日均之上，不要跳空15%</Typography>
                    <Typography variant='body1'>5. 買進的數量依據ATR算出來</Typography>
                    <Typography variant='body1'>6. 賣出掉出排名或100日均之下的股票</Typography>
                    <Typography variant='body1'>7. 每兩周一次調整庫存</Typography>
                </Stack>


                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>統計報表有什麼用途嗎？</Typography>
                <Stack direction={"column"} spacing={0.5} sx={{ p: 2 }}>
                    <Typography variant='body1'>實際操作的結果若有忠實地將買賣都記錄下來，在統計報表可以看到用此策略的報酬率，勝率與期望值。

                    </Typography>
                    <Box
                        component="img"
                        sx={{
                            // height: 233,
                            // width: 350,
                            maxHeight: { xs: '55vh', md: '600px' },
                            maxWidth: { xs: '55wh', md: '600px' },
                        }}
                        alt="book"
                        src="tran.png"
                    />
                </Stack>
            </Box>


            {/* <div class="container">
                    <div class="align-center mbr-p-1">
                        <p class="mbr-text mbr-white mbr-fonts-style display-7">
                            © Copyright 2025 喬可小舖股份有限公司 - All Rights Reserved
                        </p>
                    </div>
                </div> */}



        </Box>
    );
}

import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import { Height } from '../util/ScreenHeight'
// import ErrorM from '../util/ErrorM'
// import AskDialog from '../util/AskDialog';
// import Photo from '@mui/icons-material/Photo'
// import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import MoreVert from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import RecSetting from './RecSetting';

import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import AlertD from '../util/AlertD';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -10,
        top: 5,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
            // width: 250,
        },
    },
};


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 0
        },
    }

    //  '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
    //     borderRight: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
    //         }`,
    // },
}));


export default function RecSelector(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [tags, setTags] = React.useState([])
    const [openSetting, setOpenSetting] = React.useState(false);

    const theme = useTheme();
    const classes = useStyles();
    const [personName, setPersonName] = React.useState([]);

    const clear = () => {
        setPersonName([])
        localStorage.removeItem("sr_rules")
    }

    const handleChange1 = (event) => {
        const {
            target: { value },
        } = event;

        if (value.length > 6) {
            setAlertMsg("最多選擇六個比率")
            setShowAlert(true)
        } else {
            setPersonName(
                typeof value === 'string' ? value.split(',') : value,
            );

            let idd = tags.filter(t => value.includes(t.name)).map(row => row.id)
            localStorage.setItem("sr_rules", JSON.stringify(idd))
        }
    };

    useEffect(() => {
        if (props.refresh != null) props.refresh(nameToId())
    }, [personName]);

    const nameToId = () => {
        return tags.filter(t => personName.includes(t.name)).map(row => row.id)
    }

    useEffect(() => {
        let rules = localStorage.getItem('sr_rules') != null ? JSON.parse(localStorage.getItem('sr_rules')) : []
        let r1 = tags.filter(t => rules.includes(t.id)).map(row => row.name)
        setPersonName(r1)
    }, [tags]);

   

    useEffect(() => {
    }, []);

    return (
        <Box sx={{ width: '100%', textAlign: 'left', display: 'inline-flex', p: 0, bgcolor: theme.palette.mode === "light" ? "secondary.light" : null }} >
            {openSetting ? <RecSetting refresh={props.refresh} open={openSetting} close={setOpenSetting}></RecSetting> : null}
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Button fullWidth variant='contained' sx={{ fontSize:16, height: Height.subheader - 3, maxHeight: Height.subheader, whiteSpace: 'nowrap', borderRadius: '0px' }} size="small" color="secondary" disableElevation onClick={e => setOpenSetting(true)}>設定</Button>
        </Box>
    );
}

import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import AllStrageRules from '../strage/AllStrageRules'

export default function StrageDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [nowRows, setNowRows] = React.useState(localStorage.getItem('mv_search_rules') != null ? JSON.parse(localStorage.getItem('mv_search_rules')) : [])

    const clearRules = () => {
        setNowRows([]);
    };


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    const handleOKClose = () => {
        localStorage.setItem("mv_search_rules", JSON.stringify(nowRows))

        setOpen(false);
        if (props.close != null) props.close(false)

        if (props.refresh != null) {
            props.refresh()
        }
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    const updateRule = (id, data, enabled) => {
        if (enabled) {
            const rules = nowRows.filter(item => item.id !== id);
            const finalRules = rules.concat(data)
            setNowRows(finalRules)
        } else {
            const rules = nowRows.filter(item => item.id !== id)
            setNowRows(rules)
        }
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"股票過濾"}
                    <Typography color="red" variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center', ml: 1 }}>VIP專屬</Typography>
                </DialogTitle>
                <DialogContent  sx={{pl: 1, pr: 1}}>
                    <AllStrageRules updateRule={updateRule} rows={nowRows} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={clearRules}>清除規則</Button>
                    <Button onClick={handleClose}>取消</Button>
                    <Button disabled={localStorage.getItem("noad") != "1"} onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

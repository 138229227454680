import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';


echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function MatchKChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    function genoption1() {
        let k = {
            // axisPointer:{
            //     show: true,
            //     handle:{
            //         show: true
            //     }
            // },
            animation: false,
            title: [
                {
                    top: '0',
                    left: 'center',
                    subtext:"股價走勢圖",
                    text: props.row.company1.short_name,
                    textStyle: {
                        fontSize: 22
                    }
                }, {
                    top: '250',
                    left: 'center',
                    subtext:"股價走勢圖",
                    text: props.row.company2.short_name,
                    textStyle: {
                        fontSize: 22
                    }
                }, {
                    top: '500',
                    left: 'center',
                    text: "報酬率%",
                    subtext:"240日報酬率",
                    textStyle: {
                        fontSize: 22
                    }
                }
            ],
            tooltip: {
                show: true
            },
            // legend: {
            //     show: true
            // },
            axisPointer: {
                show: true,
                link: { xAxisIndex: 'all' },
            },
            grid: [{
                top: 30,
                left: 0,
                right: 20,
                height: 200,
                containLabel: true
            }, {
                top: 280,
                left: 0,
                right: 20,
                height: 200,
                containLabel: true
            }, {
                top: 540,
                left: 0,
                right: 20,
                height: 150,
                containLabel: true
            }],
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: props.row.price1xy.map(row => row.x),
                gridIndex: 0,
                axisPointer:{
                    show: true,
                },
            }, {
                type: 'category',
                boundaryGap: false,
                data: props.row.price2xy.map(row => row.x),
                gridIndex: 1,
                axisPointer:{
                    show: true,
                },
            }, {
                type: 'category',
                boundaryGap: false,
                data: props.row.action_xy.map(row => row.x),
                gridIndex: 2,
                axisPointer:{
                    show: true,
                    handle:{
                        show: true
                    }
                },
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                gridIndex: 0
            }, {
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                gridIndex: 1
            }, {
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                },
                gridIndex: 2
            }],
            dataZoom: [
                {
                    type: 'inside',
                    xAxisIndex: 'all',
                    start: fullScreen ? 75 : 0,
                    end: 100
                }
            ],
            series: [
                {
                    name: props.row.company1.short_name,
                    type: 'line',
                    data: props.row.price1xy.map(row => row.y),
                    smooth: true,
                    showSymbol: false,
                    lineStyle: {
                        width: 3
                    },
                    markPoint: {
                        data: props.row.action.map(row =>({
                            coord: [row.x, row.p1], 
                            symbol: "circle",//"triangle",
                            symbolSize: 6,
                            symbolRotate: row.action === 0? 180:0,
                            symbolOffset: row.action === 0? [0, -15]:[0, 15],
                            itemStyle: {
                                color: row.action === 0? "#4ba629":"#d12a4b"
                            }                            
                        }))
                    }
                },
                {
                    name: props.row.company2.short_name,
                    type: 'line',
                    data: props.row.price2xy.map(row => row.y),
                    smooth: true,
                    showSymbol: false,
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    lineStyle: {
                        width: 3
                    },
                    markPoint: {
                        data: props.row.action.map(row =>({
                            symbolRotate: row.action === 0? 0:180,
                            symbolOffset: row.action === 0? [0, 15]:[0, -15],
                            coord: [row.x, row.p2], 
                            symbol: "circle",
                            symbolSize: 6,
                            itemStyle: {
                                color: row.action === 0? "#d12a4b":"#4ba629"
                            }
                        }))
                    }                    

                },
                // {
                //     name: '股價比',
                //     type: 'line',
                //     data: props.row.action_xy.map(row => row.r),
                //     smooth: true,
                //     showSymbol: false,
                //     xAxisIndex: 2,
                //     yAxisIndex: 2,
                //     lineStyle: {
                //         width: 3
                //     }
                // }, 
                {
                    name: props.row.company1.short_name + '報酬率',
                    type: 'line',
                    data: props.row.action_xy.map(row => row.roi1),
                    smooth: true,
                    showSymbol: false,
                    xAxisIndex: 2,
                    yAxisIndex: 2,
                    lineStyle: {
                        width: 3
                    }
                }, {
                    name: props.row.company2.short_name + '報酬率',
                    type: 'line',
                    data: props.row.action_xy.map(row => row.roi2),
                    smooth: true,
                    showSymbol: false,
                    xAxisIndex: 2,
                    yAxisIndex: 2,
                    lineStyle: {
                        width: 3
                    }
                },                
                // {
                //     name: '上臨界',
                //     type: 'line',
                //     data: props.row.action_xy.map(row => row.maxl),
                //     smooth: true,
                //     showSymbol: false,
                //     xAxisIndex: 2,
                //     yAxisIndex: 2,
                //     lineStyle: {
                //         type:"dashed",
                //         width: 3
                //     }
                // }, {
                //     name: '中線',
                //     type: 'line',
                //     data: props.row.action_xy.map(row => row.avg),
                //     smooth: true,
                //     showSymbol: false,
                //     xAxisIndex: 2,
                //     yAxisIndex: 2,
                //     lineStyle: {
                //         type:"dashed",
                //         width: 3
                //     }
                // }, {
                //     name: '下臨界',
                //     type: 'line',
                //     data: props.row.action_xy.map(row => row.minl),
                //     smooth: true,
                //     showSymbol: false,
                //     xAxisIndex: 2,
                //     yAxisIndex: 2,
                //     lineStyle: {
                //         type:"dashed",
                //         width: 3
                //     }
                // }
            ]
        };
        return k;
    }

    useEffect(() => {
        // fetchData()
        if (props.row != null) setOption(genoption1)
    }, []);

    const clickDetail = () => {
        if (props.setOpen != null) props.setOpen(true)
    }

    const getStock1 = (action, company) => {
        if(action === 0) return <Button disableElevation variant="contained" color="success">{company.short_name}{" 做空"}</Button>
        else if(action === 1) return <Button disableElevation variant="contained" color="error">{company.short_name}{" 做多"}</Button>
        else return <Button variant="contained" disableElevation color="inherit">{company.short_name}{" -"}</Button>
    } 

    const getStock2 = (action, company) => {
        if(action === 0) return <Button disableElevation variant="contained" color="error">{company.short_name}{" 做多"}</Button>
        else if(action === 1) return <Button disableElevation variant="contained" color="success">{company.short_name}{" 做空"}</Button>
        else return <Button variant="contained" disableElevation color="inherit">{company.short_name}{" -"}</Button>
    } 


    return (
        <div style={{ padding: 1 }} onClick={e => clickDetail()}>
            <div style={{marginTop: 10}}>
            <Typography fontWeight={"bold"} fontSize={14}>觀察重點</Typography>
            <Typography variant="caption" fontSize={14}>股價走勢匹配程度（至少85%以上）越高的兩支股票，若發生個別漲幅異常的不一致時，則可放空漲幅大的股票，並買進漲幅低的股票。下圖中紅色點為買進信號，綠色為放空訊號。</Typography>
            </div>
            {option != null ?
                <ReactEChartsCore
                    theme={theme.palette.mode}
                    echarts={echarts}
                    option={option}
                    notMerge={true}
                    lazyUpdate={false}
                    style={{ height: 730, marginTop: 10 }}
                // onEvents={onEvents}
                />
                : null}

            {/* {props.row != null && <TableContainer component={Paper}>
                <Table sx={{ }} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>日期</StyledTableCell>
                            <StyledTableCell>股票1</StyledTableCell>
                            <StyledTableCell>股票2</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.row.action1.map((row) => (
                            <StyledTableRow key={row.x}>
                                <StyledTableCell component="th" scope="row">
                                    {row.x}
                                </StyledTableCell>
                                <StyledTableCell>{getStock1(row.action, props.row.company1)}</StyledTableCell>
                                <StyledTableCell>{getStock2(row.action, props.row.company2)}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>} */}

        </div>
    );
}


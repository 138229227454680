import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { Button, Divider, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { CheckBox } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { purple, deepOrange, green, red, blueGrey, blue, pink, grey } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getId, numberWithCommas } from '../util/ID'
import { putInpQueryParam } from '../util/ID'
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Height } from '../util/ScreenHeight'
import SellDialog from './SellDialog'
import AlertD from '../util/AlertD'
import BuyRuleDialog from './BuyRuleDialog'
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import BuyDialog1 from './BuyDialog1'
import ButtonGroup from '@mui/material/ButtonGroup';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import { Adsense } from '@ctrl/react-adsense';

echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // fontWeight:"bold",
        fontSize: 18,
        whiteSpace: "nowrap"
        // backgroundColor: "#313f57",
        // color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        whiteSpace: "nowrap",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function BigTable(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(1)
    const [result, setResult] = React.useState(null);
    const [rate, setRate] = React.useState(null);
    const [option, setOption] = React.useState(null);

    const [loading, setLoading] = React.useState(false)

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [row, setRow] = React.useState(null);


    const [age, setAge] = React.useState("");

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getBAvg = () => {
        return localStorage.getItem("mv_bavg") != null ? localStorage.getItem("mv_bavg") : 100
    }

    const getATR = () => {
        return localStorage.getItem("mv_atr") != null ? localStorage.getItem("mv_atr") : 20
    }

    const getCapital = () => {
        return localStorage.getItem("mv_capital") != null ? localStorage.getItem("mv_capital") : 300000
    }

    const getMC = () => {
        return localStorage.getItem("mv_mc") != null ? localStorage.getItem("mv_mc") : 200
    }

    const getRank = () => {
        return localStorage.getItem("mv_rank") != null ? localStorage.getItem("mv_rank") : 30
    }

    function genoption1() {
        let k = {
            animation: false,
            // title: {
            //     text: '加權指數'
            // },
            tooltip: {
                show: true
            },
            legend: {
                show: true
            },
            grid: [{
                top: 50,
                left: 5,
                right: 20,
                height: 200,
                containLabel: true
            }, {
                top: 270,
                left: 5,
                right: 20,
                height: 200,
                containLabel: true
            }],
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: result.l.map(row => row.d),
            }, {
                type: 'category',
                boundaryGap: false,
                data: result.l.map(row => row.d),
                gridIndex: 1
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                }
            }, {
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                },
                gridIndex: 1
            }],
            axisPointer: {
                show: true,
                link: { xAxisIndex: 'all' }
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: fullScreen ? 75 : 0,
                    end: 100,
                    xAxisIndex: [0, 1]
                }
            ],
            series: [
                {
                    name: '加權指數',
                    type: 'line',
                    data: result.l.map(row => row.v1),
                    smooth: true,
                    showSymbol: false,
                    markPoint: {
                        symbolSize: 12,
                        symbol: "triangle",
                        data: result.r.map(row => {
                            if (row.z === 0)
                                return { xAxis: row.x, yAxis: row.y, symbolOffset: [0, 20], itemStyle: { color: red[500] } }
                            else return { xAxis: row.x, yAxis: row.y, symbolRotate: -180, symbolOffset: [0, -20], itemStyle: { color: green[500] } }
                        }),
                    }
                }, {
                    name: getBAvg() + '日均線',
                    type: 'line',
                    data: result.l.map(row => row.v2),
                    smooth: true,
                    showSymbol: false,
                }, {
                    name: '市值指數',
                    type: 'line',
                    data: result.l.map(row => row.v3),
                    smooth: true,
                    showSymbol: false,
                    markPoint: {
                        symbolSize: 12,
                        symbol: "triangle",
                        data: result.r1.map(row => {
                            if (row.a1 === 0)
                                return { xAxis: row.x, yAxis: row.a, symbolOffset: [0, 20], itemStyle: { color: red[500] } }
                            else return { xAxis: row.x, yAxis: row.a, symbolRotate: -180, symbolOffset: [0, -20], itemStyle: { color: green[500] } }
                        }),
                    },
                    xAxisIndex: 1,
                    yAxisIndex: 1
                }, {
                    name: '市值' + getBAvg() + '日均線',
                    type: 'line',
                    data: result.l.map(row => row.v4),
                    smooth: true,
                    showSymbol: false,
                    xAxisIndex: 1,
                    yAxisIndex: 1
                }
            ]
        };
        return k;
    }

    useEffect(() => {
        // fetchData()
        if (result != null) setOption(genoption1)
    }, [result]);

    const getCp = () => {
        return localStorage.getItem("mv_cp") != null ? localStorage.getItem("mv_cp") : 0
    }

    const getMD = () => {
        return localStorage.getItem("mv_md") != null ? localStorage.getItem("mv_md") : 90
    }

    const fetchData = () => {
        setLoading(true)

        let param = {
            page: page,
            avg: getBAvg(),
            sn: getId(),
            cp: getCp(),
            md: getMD()
        }

        if (localStorage.getItem("move_lic") != null) {
            param['lic'] = localStorage.getItem("move_lic")
        }


        fetch("/move/v1/main/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }



    useEffect(() => {
        fetchData()
    }, [page]);

    const getNoAd = () => {
        return localStorage.getItem('noad') != null ? localStorage.getItem('noad') : "0"
    }

    return (
        <>
            {openSetting ? <SellDialog row={row} refresh={fetchData} open={openSetting} close={setOpenSetting}></SellDialog> : null}
            {openSetting1 ? <BuyRuleDialog row={row} refresh={fetchData} open={openSetting1} close={setOpenSetting1}></BuyRuleDialog> : null}
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting2 ? <BuyDialog1 row={row} refresh={fetchData} open={openSetting2} close={setOpenSetting2}></BuyDialog1> : null}

            <Box sx={{ p: 0, width: "100%", overflowY: 'hidden' }}>
                <TableContainer component={Paper} sx={{ position: 'absolute', top: Height.header, bottom: props.ismobile || fullScreen ? 60 : 0, mt: 1.5 }}>
                    <div>
                        <Typography sx={{ mt: 3, textAlign: "center" }} variant="h4">市場多空判斷</Typography>
                        
                        {getNoAd() === "0" && <Adsense
                            client="ca-pub-8613274553211553"
                            slot="1589318149"
                            style={{ display: 'block' }}
                            layout="in-article"
                            format="fluid"
                        />}

                        {option != null ?
                            <ReactEChartsCore
                                theme={theme.palette.mode}
                                echarts={echarts}
                                option={option}
                                notMerge={true}
                                lazyUpdate={false}
                                style={{ height: 500, marginTop: 20 }}
                            // onEvents={onEvents}
                            />
                            : null}
                    </div>
                    <Table sx={{ mt: 2 }} stickyHeader size="small">
                        <TableHead>
                            <TableRow >
                                <StyledTableCell>指標</StyledTableCell>
                                <StyledTableCell align="left">日期</StyledTableCell>
                                <StyledTableCell>多空確認</StyledTableCell>
                                <StyledTableCell>維持天數</StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result != null && result.r.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell align="left">
                                        {"加權指數"}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.x}
                                    </StyledTableCell>


                                    <StyledTableCell align="left">
                                        {row.z === 0 ?
                                            <Button disableElevation variant='contained' sx={{
                                                bgcolor: red[400], width: 120, ':hover': {
                                                    bgcolor: red[300], // theme.palette.primary.main
                                                    color: 'white',
                                                }
                                            }}>轉成多方</Button> :
                                            <Button disableElevation variant='contained' sx={{
                                                bgcolor: green[500], width: 120, ':hover': {
                                                    bgcolor: green[400], // theme.palette.primary.main
                                                    color: 'white',
                                                }
                                            }}>轉成空方</Button>}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.z1}天
                                    </StyledTableCell>

                                </StyledTableRow>
                            ))}
                            {result != null && result.r1.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell align="left">
                                        {"市值指數"}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.x}
                                    </StyledTableCell>


                                    <StyledTableCell align="left">
                                        {row.a1 === 0 ?
                                            <Button disableElevation variant='contained' sx={{
                                                bgcolor: red[400], width: 120, ':hover': {
                                                    bgcolor: red[300], // theme.palette.primary.main
                                                    color: 'white',
                                                }
                                            }}>轉成多方</Button> :
                                            <Button disableElevation variant='contained' sx={{
                                                bgcolor: green[500], width: 120, ':hover': {
                                                    bgcolor: green[400], // theme.palette.primary.main
                                                    color: 'white',
                                                }
                                            }}>轉成空方</Button>}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.a2}天
                                    </StyledTableCell>

                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {/* {
                result != null && result.length > 0 && < Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                    <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
                </Box>
            } */}
        </>
    );
}

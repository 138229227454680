import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import SearchCompany from '../../love/SearchCompany';
import { getId } from '../../util/ID'
import { Alert } from '@mui/material';
import AlertD from '../../util/AlertD';

export default function AddLoveDialog(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [open, setOpen] = React.useState(false);
    const [tp, setTP] = React.useState(0);
    const [sid, setSID] = React.useState(null);
    const [tags, setTags] = React.useState([])
    const [brokers, setBrokers] = React.useState([])
    const [id, setID] = React.useState("");
    const [bid, setBID] = React.useState("");

    useEffect(() => {
        if (tags != null && tags.length > 0) setID("")
    }, [tags]);

    useEffect(() => {
        if (brokers != null && brokers.length > 0) setBID("")
    }, [brokers]);

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        setOpen(false);
        clickLove()
        if (props.close != null) props.close(false)
    };


    const clickLove = () => {
        if (sid != null) {
            let param = {
                sn: getId(),
                sid: sid,
            }

            if (localStorage.getItem("expect_lic") != null) {
                param['lic'] = localStorage.getItem("expect_lic")
            }

            fetch("/move/v1/expect/love/click", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null && result=== 0) {
                            if (props.refresh != null) props.refresh()
                            // if (props.updateLove != null) props.updateLove(props.row.stock)
                        }
                    }
                );
        } else {
            setAlertMsg("請輸入股票代碼。")
            setShowAlert(true)
        } 
    }



    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    useEffect(() => {
    }, []);

    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"新增我的最愛"}
                </DialogTitle>

                <DialogContent>
                    <Stack spacing={3} direction="column" sx={{ p: 2 }}>
                        <SearchCompany setSID={setSID} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

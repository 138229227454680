import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { Alert, Divider, TextField, Typography } from '@mui/material';
import AlertD from '../util/AlertD';
import { getId, numberWithCommas } from '../util/ID'
import InputAdornment from '@mui/material/InputAdornment';
import SearchCompany from '../love/SearchCompany'

export default function BuyDialog1(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [open, setOpen] = React.useState(false);
    const [bPrice, setBPrice] = React.useState("");
    const [num, setNum] = React.useState("");
    const [bYMD, setBYMD] = React.useState("");
    const [sid, setSID] = React.useState(null);
    const [row, setRow] = React.useState(null);

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        clickLove()
    };

    const getAvg = () => {
        return localStorage.getItem("mv_avg") != null ? localStorage.getItem("mv_avg") : 100
    }

    const getATR = () => {
        return localStorage.getItem("mv_atr") != null ? localStorage.getItem("mv_atr") : 20
    }

    const getCapital = () => {
        return localStorage.getItem("mv_capital") != null ? localStorage.getItem("mv_capital") : 300000
    }

    const getMC = () => {
        return localStorage.getItem("mv_mc") != null ? localStorage.getItem("mv_mc") : 200
    }


    const fetchData = () => {
        let risk = localStorage.getItem("mv_risk") != null ? localStorage.getItem("mv_risk") : 0.1
        let gap = localStorage.getItem("mv_gap") != null ? localStorage.getItem("mv_gap") : 15

        let param = {
            capital: getCapital(),
            risk: parseFloat(risk) / 100,
            gap: gap,
            avg: getAvg(),
            atr_days: getATR(),
            mc: getMC(),
            sn: getId(),
            sid: sid
        }

        if (localStorage.getItem("move_lic") != null) {
            param['lic'] = localStorage.getItem("move_lic")
        }


        fetch("/move/v1/app/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        if (result.data != null && result.data.length > 0) {
                            setRow(result.data[0])
                        }
                    }
                }
            );
    }

    useEffect(() => {
        if(sid != null && sid != "") {
            fetchData()
        } else {
            setRow(null)
        }
    }, [sid]);

    const getCp = () => {
        return localStorage.getItem("mv_cp") != null ? localStorage.getItem("mv_cp") : 0
    }

    const getMD = () => {
        return localStorage.getItem("mv_md") != null ? localStorage.getItem("mv_md") : 90
    }

    const clickLove = () => {
        if (sid != null && bPrice != "" && bYMD != "" && num != "") {
            setOpen(false);
            if (props.close != null) props.close(false)

            let risk = localStorage.getItem("mv_risk") != null ? localStorage.getItem("mv_risk") : 0.1
            let gap = localStorage.getItem("mv_gap") != null ? localStorage.getItem("mv_gap") : 15

            let param = {
                sn: getId(),
                sid: sid,
                bprice: bPrice,
                bymd: bYMD,
                num: num,
                capital: getCapital(),
                risk: parseFloat(risk) / 100,
                gap: gap,
                avg: getAvg(),
                atr_days: getATR(),
                mc: getMC(),
                cp : getCp(),
                md: getMD()
            }

            if (localStorage.getItem("move_lic") != null) {
                param['lic'] = localStorage.getItem("move_lic")
            }

            fetch("/move/v1/app/buy", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null && result.status === 0) {
                            if (props.refresh != null) props.refresh()
                            // if (props.updateLove != null) props.updateLove(row.stock)
                        }
                    }
                );
        } else {
            setAlertMsg("請輸入股票購買價格，數量與日期。")
            setShowAlert(true)
        }
    }



    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    買進股票
                </DialogTitle>
                {/* <Divider></Divider> */}

                <DialogContent sx={{ p: 3 }}>
                    {/* <Typography fontWeight={"bold"} variant='subtitle1'>參考</Typography> */}

                    <Stack spacing={0} direction="column" sx={{ mt: 1 }}>
                        <SearchCompany setSID={setSID} />
                    </Stack>

                    {row != null && sid != null && <Stack spacing={0.2} direction="column" sx={{ mt: 2 }}>
                        <Stack direction={"row"}>
                            <Typography fontWeight={"bold"} variant="subtitle1" >動能排序名次：</Typography>
                            <Typography sx={{ color: "gray" }} variant="subtitle1">
                                {row.rank} </Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <Typography fontWeight={"bold"} variant="subtitle1" >總資金：</Typography>
                            <Typography sx={{ color: "gray" }} variant="subtitle1">
                                {numberWithCommas(getCapital())}元</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <Typography fontWeight={"bold"} variant="subtitle1" >資金百分比：</Typography>
                            <Typography sx={{ color: "gray" }} variant="subtitle1">
                                {row.risk}%</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <Typography fontWeight={"bold"} variant="subtitle1" >目前價格：</Typography>
                            <Typography sx={{ color: "gray" }} variant="subtitle1">
                                {row.price.p}元</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <Typography fontWeight={"bold"} variant="subtitle1" >建議股數：</Typography>
                            <Typography sx={{ color: "gray" }} variant="subtitle1">
                                {numberWithCommas(row.num)}股</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                            <Typography fontWeight={"bold"} variant="subtitle1" >建議金額：</Typography>
                            <Typography sx={{ color: "gray" }} variant="subtitle1">
                                {numberWithCommas(row.cost)}元
                            </Typography>
                        </Stack>
                    </Stack>}

                    <Stack spacing={1} direction="column" sx={{ mt: 2 }}>
                        <TextField
                            value={bPrice}
                            onChange={event => setBPrice(event.target.value)}
                            inputProps={{  }}
                            size="medium" label="買進價位" fullWidth InputProps={{
                                endAdornment: <InputAdornment position="end">元</InputAdornment>,
                            }}></TextField>
                        <TextField
                            value={num}
                            onChange={event => setNum(event.target.value)}
                            size="medium" label="買進股數" fullWidth
                            inputProps={{  }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">股</InputAdornment>,
                            }}></TextField>
                        <TextField size="medium" label="買入日期" fullWidth
                            value={bYMD}
                            onChange={event => setBYMD(event.target.value)}
                            helperText="YYYYMMDD，例如：20220930"
                            inputProps={{  }}
                        ></TextField>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

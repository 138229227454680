import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { deepOrange, green } from '@mui/material/colors';
import Badge from '@mui/material/Badge';
import KChart from './KChart';

export default function RecBase(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const openMenu = Boolean(anchorEl);

    const getOvrate = () => {
        return (((props.row.cb2.bprice / props.row.cb2.gprice) - 1) * 100).toFixed(2)
    }

    const clickDetail = () => {
        if (props.setOpen != null) props.setOpen(true)
    }

    const get50Color = (rank) => {
        if (rank >= 50) return "error"
        // else if (rank < 50) return "green"
        else return "inherit"
    }

    const get0Color = (rank) => {
        if (rank > 0) return "error"
        else if (rank < 0) return "green"
        else return "inherit"
    }

    const openDetail = (stockNo) => {
        if (props.openDetail != null) {
            props.openDetail(stockNo)
        }
    }

    const openDetail2 = (id) => {
        if (props.openDetail2 != null) {
            props.openDetail2(id)
        }
    }

    const openDetail3 = (id) => {
        if (props.openDetail3 != null) {
            props.openDetail3(id)
        }
    }

    const openDetail4 = (row) => {
        if (props.openDetail4 != null) {
            props.openDetail4(row)
        }
    }

    return (
        <Box sx={{ mt: 0 }} onClick={e => clickDetail()}>
           

            <ButtonGroup variant="text" aria-label="text button group" sx={{ width: '100%', mt: 0, mb: 0, justifyContent: 'center' }}>
                <Button sx={{ width: '33%' }} color="inherit" key={"1"} >
                    <Stack sx={{ justifyContent: 'center', width: "100%" }} >
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >報酬率%</Typography>
                        <Typography color={get0Color(props.row.tr.roi)} sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.tr.roi}</Typography>
                        <Button fullWidth size="small" disableElevation color="secondary" variant="contained" onClick={e => openDetail2(props.row.id)} >賣出明細</Button>
                    </Stack>
                </Button>
                <Button sx={{ width: '33%' }} color="inherit" key={"5"} >
                    <Stack sx={{ justifyContent: 'center', width: "100%" }} >
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >未實現報酬率%</Typography>
                        <Typography color={get0Color(props.row.tr.frate)} sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.tr.frate}</Typography>
                        <Button fullWidth size="small" disableElevation color="secondary" variant="contained" onClick={e => openDetail3(props.row.id)}>未賣明細</Button>
                    </Stack>
                </Button>
                <Button sx={{ width: '33%' }} color="inherit" key={"2"} >
                    <Stack sx={{ justifyContent: 'center', width: "100%" }} >
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >勝率%</Typography>
                        <Typography color={get50Color(props.row.tr.win_rate)} sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.tr.win_rate}</Typography>
                        <Button fullWidth size="small" disableElevation color="secondary" variant="contained" onClick={e => openDetail4(props.row)}>其他數據</Button>
                    </Stack>
                </Button>

            </ButtonGroup>

            <Divider variant="fullWidth" sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    // margin: 0,
                }}
            />

            <ButtonGroup variant="text" aria-label="text button group" sx={{ width: '100%', mt: 0, mb: 0, justifyContent: 'center' }}>
                <Button sx={{ width: '33%' }} color="inherit" key={"3"} >
                    <Stack sx={{ justifyContent: 'center', width: "100%" }} >
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >期望值(元)</Typography>
                        <Typography color={get0Color(props.row.tr.expv)} sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.tr.expv}</Typography>
                    </Stack>
                </Button>
                <Button sx={{ width: '33%' }} color="inherit" key={"4"} >
                    <Stack sx={{ justifyContent: 'center', width: "100%" }} >
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >交易次數</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.tr.tran_count}</Typography>
                    </Stack>
                </Button>

                <Button sx={{ width: '33%' }} color="inherit" key={"6"} >
                    <Stack sx={{ justifyContent: 'center', width: "100%" }} >
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >平均持有天數</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.tr.avghold}</Typography>
                    </Stack>
                </Button>
            </ButtonGroup>

        </Box>

    );
}

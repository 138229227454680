import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart, PieChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import Typography from '@mui/material/Typography';

echarts.use(
    [PieChart, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function CategoryChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);

    function genoption1() {
        let k = {
            title: {
                text: '產業數量'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            // legend: {},
            grid: {
                top: 30,
                left: 10,
                right: 30,
                bottom: 10,
                containLabel: true
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01]
            },
            yAxis: {
                type: 'category',
                data: ['電子業', '水泥', '房地產', '金融', '保險', '醫療'],
                axisLabel: {
                    fontSize: 12
                }
            },
            label: {
                show: true,
                position:"right"
            },
            series: [
                {
                    name: '產業類別',
                    type: 'bar',
                    data: [18203, 23489, 29034, 104970, 131744, 630230],
                    barCategoryGap: '40%',
                },
                // {
                //     name: '2012',
                //     type: 'bar',
                //     data: [19325, 23438, 31000, 121594, 134141, 681807]
                // }
            ]
        }
        return k;
    }

    useEffect(() => {
        // fetchData()
        setOption(genoption1)
    }, []);

    const clickDetail = () => {
        if (props.setOpen != null) props.setOpen(true)
    }

    return (
        <div style={{ padding: 1 }} onClick={e => clickDetail()}>
            {/* <Typography fontWeight={"bold"} fontSize={14}>股價走勢</Typography> */}
            {option != null ?
                <ReactEChartsCore
                    theme={theme.palette.mode}
                    echarts={echarts}
                    option={option}
                    notMerge={true}
                    lazyUpdate={false}
                style={{ height: '30vh' }}
                // onEvents={onEvents}
                />
                : null}
        </div>
    );
}


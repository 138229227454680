import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AllStrageRules from '../strage/AllStrageRules'
import Checkbox from '@mui/material/Checkbox';
import { Height } from '../util/ScreenHeight'
import ListItemText from '@mui/material/ListItemText';
import FormHelperText from '@mui/material/FormHelperText';
import AlertD from '../util/AlertD';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
            // width: 250,
        },
    },
};

const currencies = [
    {
        value: "0",
        label: "股票+ETF",
    },
    {
        value: '1',
        label: "全部股票",
    },
    {
        value: '2',
        label: '上市股票',
    },
    {
        value: '3',
        label: '上櫃股票',
    },
    {
        value: '4',
        label: 'ETF',
    },
];

export default function RecSetting(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const [period, setPeriod] = React.useState("365");
    const [intervala, setIntervala] = React.useState("7");

    const [capital, setCapital] = React.useState(localStorage.getItem("b_mv_capital") != null ? localStorage.getItem("b_mv_capital") : 300000);
    const [risk, setRisk] = React.useState(localStorage.getItem("b_mv_risk") != null ? localStorage.getItem("b_mv_risk") : 0.1);
    const [avg, setAvg] = React.useState(localStorage.getItem("b_mv_avg") != null ? localStorage.getItem("b_mv_avg") : 100);
    const [gap, setGap] = React.useState(localStorage.getItem("b_mv_gap") != null ? localStorage.getItem("b_mv_gap") : 15);
    const [atr, setATR] = React.useState(localStorage.getItem("b_mv_atr") != null ? localStorage.getItem("b_mv_atr") : 20);
    const [mc, setMC] = React.useState(localStorage.getItem("b_mv_mc") != null ? localStorage.getItem("b_mv_mc") : 200);
    const [mp, setMP] = React.useState(localStorage.getItem("b_mv_mp") != null ? localStorage.getItem("b_mv_mp") : 7000);
    const [rank, setRank] = React.useState(localStorage.getItem("b_mv_rank") != null ? localStorage.getItem("b_mv_rank") : 50);
    const [cp, setCP] = React.useState(localStorage.getItem("b_mv_cp") != null ? localStorage.getItem("b_mv_cp") : 0);
    const [md, setMD] = React.useState(localStorage.getItem("b_mv_md") != null ? localStorage.getItem("b_mv_md") : 90);
    const [big, setBig] = React.useState(localStorage.getItem("b_mv_big") != null ? localStorage.getItem("b_mv_big") : 100);


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        // localStorage.setItem("sr_backtest_rules", JSON.stringify(nameToId()))
        if (capital != "" && capital != null) localStorage.setItem("b_mv_capital", capital)
        if (risk != "" && risk != null) localStorage.setItem("b_mv_risk", risk)
        if (avg != "" && avg != null) localStorage.setItem("b_mv_avg", avg)
        if (gap != "" && gap != null) localStorage.setItem("b_mv_gap", gap)
        if (atr != "" && atr != null) localStorage.setItem("b_mv_atr", atr)
        if (mc != "" && mc != null) localStorage.setItem("b_mv_mc", mc)
        if (mp != "" && mp != null) localStorage.setItem("b_mv_mp", mp)
        else localStorage.removeItem("b_mv_mp")
        if (rank != "" && rank != null) localStorage.setItem("b_mv_rank", rank)
        if (cp != "" && cp != null) localStorage.setItem("b_mv_cp", cp)
        if (md != "" && md != null) localStorage.setItem("b_mv_md", md)
        if (big != "" && big != null) localStorage.setItem("b_mv_big", big)


        createBackTest()
    };


    // period : Int = 720, interval : Int = 7, outRank : Int = 50, capital : Long = 500000, 
    //     risk : Double = 0.1, atrDays : Int = 20, gap : Double = 15, avg : Int = 90, mc : Int = 500, mp : Option[Double] = None

    const createBackTest = () => {
        let param = {
            req: {
                period: period,
                interval: intervala,
                out_rank: rank,
                capital: capital,
                risk: parseFloat(risk) / 100,
                atr_days: atr,
                gap: gap,
                avg: avg,
                mc: mc,
                mp: mp,
                cp: cp,
                md: md,
                big: big
            }
        }

        if (localStorage.getItem("move_lic") != null) {
            param['lic'] = localStorage.getItem("move_lic")
        }

        fetch("/move/v1/backtest/test", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        setOpen(false);
                        if (props.close != null) props.close(false)
                        if (props.refresh != null) props.refresh()
                    } else if (result != null && result.status === -1) {
                        setAlertMsg("序號錯誤，請檢查是否有輸入序號。")
                        setShowAlert(true)
                    } else {
                        setAlertMsg("新增失敗，請稍後再嘗試。")
                        setShowAlert(true)
                    }
                }
            );
    }


    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"新增回測"}
                    <Typography color="red" variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center', ml: 1 }}>VIP專屬</Typography>
                </DialogTitle>
                <DialogContent sx={{ pl: 0.5, pr: 0.5 }}>


                    <Typography fontWeight={"bold"} variant='body1' sx={{ pl: 2 }}>買入條件</Typography>
                    <Stack spacing={1.5} direction="column" sx={{ p: 2 }}>
                        <TextField
                            size="medium"
                            id="A"
                            select
                            label="類型"
                            value={cp}
                            onChange={event => setCP(event.target.value)}
                            helperText="用什麼去計算動能排名，預設：全部股票+ETF"
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField size="medium" label="動能天數" fullWidth
                            value={md}
                            onChange={event => setMD(event.target.value)}
                            helperText="用近幾日去計算動能大小，預設：90"
                            inputProps={{}}
                        ></TextField>

                        <TextField size="medium" label="市值" fullWidth
                            value={mc}
                            onChange={event => setMC(event.target.value)}
                            helperText="市值排名前N名，預設：200"
                            inputProps={{}}
                        ></TextField>

                        <TextField size="medium" label="均線" fullWidth
                            value={avg}
                            onChange={event => setAvg(event.target.value)}
                            helperText="股價要大於N日均線，預設：100"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">日</InputAdornment>,
                            }}
                            inputProps={{}}
                        ></TextField>
                        <TextField size="medium" label="跳空" fullWidth
                            value={gap}
                            onChange={event => setGap(event.target.value)}
                            helperText="2日跳空不能超過N%。預設：15%"
                            inputProps={{}}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        ></TextField>
                        <TextField size="medium" label="最大價格" fullWidth
                            value={mp}
                            onChange={event => setMP(event.target.value)}
                            helperText=""
                            inputProps={{}}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">元</InputAdornment>,
                            }}
                        ></TextField>
                        <TextField size="medium" label="均線" fullWidth
                            value={big}
                            onChange={event => setBig(event.target.value)}
                            helperText="加權指數要大於N日均線，預設：100"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">日</InputAdornment>,
                            }}
                            inputProps={{}}
                        ></TextField>
                    </Stack>

                    <Typography fontWeight={"bold"} variant='body1' sx={{ mt: 2, pl: 2 }}>資金配置</Typography>
                    <Stack spacing={1.5} direction="column" sx={{ p: 2 }}>
                        <TextField
                            value={capital}
                            onChange={event => setCapital(event.target.value)}
                            inputProps={{}}
                            size="medium" label="總資金" fullWidth InputProps={{
                                endAdornment: <InputAdornment position="end">元</InputAdornment>,
                            }}></TextField>
                        <TextField
                            value={risk}
                            onChange={event => setRisk(event.target.value)}
                            size="medium" label="風險因子" fullWidth
                            helperText="數值越大，可購買股票越少，風險越大。預設：0.1%"
                            inputProps={{}}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}></TextField>

                        <TextField size="medium" label="ATR" fullWidth
                            value={atr}
                            onChange={event => setATR(event.target.value)}
                            helperText="平均真實區間。預設20日"
                            inputProps={{}}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">日</InputAdornment>,
                            }}
                        ></TextField>
                    </Stack>

                    <Typography fontWeight={"bold"} variant='body1' sx={{ mt: 2, pl: 2 }}>出場條件</Typography>
                    <Stack spacing={1.5} direction="column" sx={{ p: 2 }}>
                        <TextField size="medium" label="排名" fullWidth
                            value={rank}
                            onChange={event => setRank(event.target.value)}
                            helperText="超過動能排名N就出場，預設：50"
                            inputProps={{}}
                        ></TextField>

                        <FormControl fullWidth required>
                            <InputLabel id="period-label">回測時間</InputLabel>
                            <Select
                                labelId="period-label"
                                id="period"
                                value={period}
                                label="回測時間"
                                onChange={e => setPeriod(e.target.value)}
                            >
                                <MenuItem value={'180'}>{'近半年'}</MenuItem>
                                <MenuItem value={'365'}>{'近1年'}</MenuItem>
                                <MenuItem value={'730'}>{'近2年'}</MenuItem>
                                <MenuItem value={'1095'}>{'近3年'}</MenuItem>
                                <MenuItem value={'1460'}>{'近4年'}</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel id="interval-label">換股週期</InputLabel>
                            <Select
                                labelId="interval-label"
                                id="interval"
                                value={intervala}
                                label="換股週期"
                                onChange={e => setIntervala(e.target.value)}
                            >
                                <MenuItem value={'7'}>{'每一周'}</MenuItem>
                                <MenuItem value={'14'}>{'每兩周'}</MenuItem>
                                <MenuItem value={'30'}>{'每個月'}</MenuItem>
                                <MenuItem value={'90'}>{'每一季'}</MenuItem>
                                <MenuItem value={'180'}>{'每半年'}</MenuItem>
                            </Select>
                            <FormHelperText>例如：每周檢視是否要換股</FormHelperText>
                        </FormControl>

                    </Stack>


                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={clearRules}>清除規則</Button> */}
                    <Button disableElevation variant='contained' onClick={handleClose}>取消</Button>

                    <Button disabled={localStorage.getItem("noad") != "1"} disableElevation variant='contained' onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

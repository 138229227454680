import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import useMediaQuery from '@mui/material/useMediaQuery';

import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import KChart from './KChart';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        whiteSpace: "nowrap"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function DetailTable(props) {
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    const getRankColor = (rank) => {
        if (rank >= 98) return "error"
        else if (rank < 50) return "success"
        else return "secondary"
    }

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);



    const getROI = (row) => {
        let v = (((row.nprice / row.bprice) - 1) * 100)
        if (v > 0) return <Button sx={{ width: 90 }} variant="contained" color="error" disableElevation>{v.toFixed(2)}%</Button>
        else return <Button sx={{ width: 90 }} variant="contained" color="success" disableElevation>{v.toFixed(2)}%</Button>
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{}}>
                回測明細
            </DialogTitle>
            <DialogContent sx={{ pl: 1, pr: 1 }}>
                {props.row != null && <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center" >名稱</StyledTableCell>
                                <StyledTableCell align="center" >內容</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key={'a1'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >總交易數</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null ? props.row.tr.tran_count + '筆' : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'b1'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >勝場數</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.wc != null ? props.row.tr.wc + '筆' : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'c1'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >敗場數</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.lc != null ? props.row.tr.lc + '筆' : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'d1'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >勝率</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.win_rate != null ? props.row.tr.win_rate + '%' : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'e2'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >敗率</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.lostr != null ? props.row.tr.lostr + '%' : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'01'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >報酬率</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.roi != null ? props.row.tr.roi + '%' : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'13'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >平均報酬率</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.avroi != null ? props.row.tr.avroi + '%' : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'13b'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >平均獲利</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.avgw != null ? props.row.tr.avgw + '元' : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'13a'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >平均損失</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.avgl != null ? props.row.tr.avgl + '元' : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'13c'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >盈虧比</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.wl != null ? props.row.tr.wl : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'13d'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >期望值</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.expv != null ? props.row.tr.expv : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'13dl'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >平均持有天數</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.avghold != null ? props.row.tr.avghold + '天' : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'011'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >未實現報酬率</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.frate != null ? props.row.tr.frate + '%' : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'131'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >未實現平均報酬率</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.avfroi != null ? props.row.tr.avfroi + '%' : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'13kk'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >未實現平均持有天數</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.avghold1 != null ? props.row.tr.avghold1 + '天' : '-'}</StyledTableCell>
                            </TableRow>

                            <TableRow key={'132'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >買入金額</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.bamount != null ? ((props.row.tr.bamount / 1000).toFixed(0)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + "千元" : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'133'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >賣出金額</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.samount != null ? ((props.row.tr.samount / 1000).toFixed(0)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + "千元" : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'134'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >手續費</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null && props.row.tr.handle_fee != null ? props.row.tr.handle_fee.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + "元" : '-'}</StyledTableCell>
                            </TableRow>
                            <TableRow key={'135'}>
                                <StyledTableCell align="center" style={{ whiteSpace: 'nowrap', width: 150 }} >交易稅</StyledTableCell>
                                <StyledTableCell align="center">{props.row.tr != null ? props.row.tr.tran_fee.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + "元" : '-'}</StyledTableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>}



            </DialogContent>
            <DialogActions>
                <Button disableElevation variant="contained" color="info" onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}


import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { Alert, Divider, TextField, Typography } from '@mui/material';
import AlertD from '../util/AlertD';
import { getId, numberWithCommas } from '../util/ID'
import InputAdornment from '@mui/material/InputAdornment';

export default function BuyRuleDialog(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const [rank, setRank] = React.useState(localStorage.getItem("mv_rank") != null ? localStorage.getItem("mv_rank") : 30);
    const [avg, setAvg] = React.useState(localStorage.getItem("mv_avg") != null ? localStorage.getItem("mv_avg") : 100);
    const [gap, setGap] = React.useState(localStorage.getItem("mv_gap") != null ? localStorage.getItem("mv_gap") : 15);


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        if (rank != "" && rank != null) localStorage.setItem("mv_rank", rank)
        if (avg != "" && avg != null) localStorage.setItem("mv_avg", avg)
        if (gap != "" && gap != null) localStorage.setItem("mv_gap", gap)

        setOpen(false);
        if (props.close != null) props.close(false)
        if (props.refresh != null) props.refresh()
    };


    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    設定
                </DialogTitle>
                {/* <Divider></Divider> */}

                <DialogContent sx={{ p: 3 }}>


                    <Typography fontWeight={"bold"} variant='body1' sx={{ mt: 0 }}>賣出條件</Typography>
                    <Stack spacing={3} direction="column" sx={{ mt: 2 }}>
                        <TextField size="medium" label="排名" fullWidth
                            value={rank}
                            onChange={event => setRank(event.target.value)}
                            helperText="動能排名至少要幾名，預設：30"
                            inputProps={{  }}
                        ></TextField>

                        <TextField size="medium" label="均線" fullWidth
                            value={avg}
                            onChange={event => setAvg(event.target.value)}
                            helperText="股價要大於N日均線，預設：100"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">日</InputAdornment>,
                            }}
                            inputProps={{  }}
                        ></TextField>
                        <TextField size="medium" label="跳空" fullWidth
                            value={gap}
                            onChange={event => setGap(event.target.value)}
                            helperText="2日跳空不能超過N%。預設：15%"
                            inputProps={{  }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        ></TextField>
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button sx={{ width: 80 }} onClick={handleClose}>取消</Button>
                    <Button sx={{ width: 80 }} onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

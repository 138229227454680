import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import useMediaQuery from '@mui/material/useMediaQuery';

import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import KChart from './KChart';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getId, numberWithCommas } from '../util/ID'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      whiteSpace: "nowrap"
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export default function RestTable(props) {
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    const getRankColor = (rank) => {
        if (rank >= 98) return "error"
        else if (rank < 50) return "success"
        else return "secondary"
    }

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);



    const fetchData = () => {
        let param = {
            id: props.id,
        }

        if (localStorage.getItem("move_lic") != null) {
            param['lic'] = localStorage.getItem("move_lic")
        }
        
        fetch("/move/v1/backtest/restlist", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }


    useEffect(() => {
        fetchData()
    }, []);

    const getROI = (row) => {
        let v = (((row.nprice / row.bprice )- 1) * 100)
        if(v > 0) return <Button sx={{width: 90}} variant="contained" color="error" disableElevation>{v.toFixed(2)}%</Button>
        else return <Button sx={{width: 90}} variant="contained" color="success" disableElevation>{v.toFixed(2)}%</Button>
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{  }}>
                未實現明細
            </DialogTitle>
            <DialogContent sx={{ pl: 1, pr: 1 }}>

                {result != null && <TableContainer component={Paper}>
                    <Table sx={{ }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center" >報酬</StyledTableCell>
                                <StyledTableCell>股票</StyledTableCell>
                                <StyledTableCell align="right">股數</StyledTableCell>
                                <StyledTableCell align="right">買入價格</StyledTableCell>
                                <StyledTableCell align="right">最後價格</StyledTableCell>
                                <StyledTableCell align="right">買入日期</StyledTableCell>
                                <StyledTableCell align="right">最後日期</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result.map((row) => (
                                <StyledTableRow 
                                    key={row.sid}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell align="center">{getROI(row)}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.name} ({row.sid})
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{numberWithCommas(row.count)}</StyledTableCell>
                                    <StyledTableCell align="right">{row.bprice}元</StyledTableCell>
                                    <StyledTableCell align="right">{row.nprice}元</StyledTableCell>
                                    <StyledTableCell align="right">{row.bymd}</StyledTableCell>
                                    <StyledTableCell align="right">{row.nymd}</StyledTableCell>
                                </StyledTableRow >
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}



            </DialogContent>
            <DialogActions>
                <Button disableElevation variant="contained" color="info" onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}


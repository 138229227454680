import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart , CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import Typography from '@mui/material/Typography';

echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart ]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function KChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);

    function genoption1() {
        let k = {
            animation:false,
            grid: [{
                top: 10,
                left: 5,
                right: 0,
                // height: 100,
                bottom: 10,
                containLabel:true
            }],
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: result.map(row => row.x),
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                }                
            }],
            series: [
                {
                    name: '累積盈虧',
                    type: 'line',
                    data: result.map(row => row.y),
                    smooth: true,
                    showSymbol: false,
                }
            ]
        };
        return k;
    }

    const fetchData = () => {
        let param = {
           id: props.id
        }

        if (localStorage.getItem("move_lic") != null) {
            param['lic'] = localStorage.getItem("move_lic")
        }

        fetch("/move/v1/backtest/profit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if(result != null) {
                        setResult(result)
                    }
                }
            );
    }

    useEffect(() => {
        fetchData()
        // if(props.prices != null) setOption(genoption1)
    }, [props.id]);

    useEffect(() => {
        if(result != null) setOption(genoption1)
    }, [result]);

    const clickDetail = () => {
        if(props.setOpen != null) props.setOpen(true)
    }

    return (
        <div style={{padding:1}}  onClick={e => clickDetail()}>
            {option != null ?<>
                <Typography fontWeight={"bold"} fontSize={14}>累積盈虧</Typography>
            
                <ReactEChartsCore
                    theme={theme.palette.mode}
                    echarts={echarts}
                    option={option}
                    notMerge={true}
                    lazyUpdate={false}
                    style={{ height: 120 }}
                // onEvents={onEvents}
                /></>
                : null}
        </div>
    );
}


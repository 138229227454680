import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { Alert, Divider, TextField, Typography } from '@mui/material';
import AlertD from '../util/AlertD';
import { getId, numberWithCommas } from '../util/ID'
import InputAdornment from '@mui/material/InputAdornment';

const currencies = [
    {
      value: "0",
      label: "股票+ETF",
    },
    {
      value: '1',
      label: "全部股票",
    },
    {
      value: '2',
      label: '上市股票',
    },
    {
      value: '3',
      label: '上櫃股票',
    },
    {
      value: '4',
      label: 'ETF',
    },
  ];

export default function RuleDialog(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const [capital, setCapital] = React.useState(localStorage.getItem("mv_capital") != null ? localStorage.getItem("mv_capital") : 300000);
    const [risk, setRisk] = React.useState(localStorage.getItem("mv_risk") != null ? localStorage.getItem("mv_risk") : 0.1);
    const [avg, setAvg] = React.useState(localStorage.getItem("mv_avg") != null ? localStorage.getItem("mv_avg") : 100);
    const [gap, setGap] = React.useState(localStorage.getItem("mv_gap") != null ? localStorage.getItem("mv_gap") : 15);
    const [atr, setATR] = React.useState(localStorage.getItem("mv_atr") != null ? localStorage.getItem("mv_atr") : 20);
    const [mc, setMC] = React.useState(localStorage.getItem("mv_mc") != null ? localStorage.getItem("mv_mc") : 200);
    const [mp, setMP] = React.useState(localStorage.getItem("mv_mp") != null ? localStorage.getItem("mv_mp") : null);
    const [cp, setCP] = React.useState(localStorage.getItem("mv_cp") != null ? localStorage.getItem("mv_cp") : 0);
    const [md, setMD] = React.useState(localStorage.getItem("mv_md") != null ? localStorage.getItem("mv_md") : 90);


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        if (capital != "" && capital != null) localStorage.setItem("mv_capital", capital)
        if (risk != "" && risk != null) localStorage.setItem("mv_risk", risk)
        if (avg != "" && avg != null) localStorage.setItem("mv_avg", avg)
        if (gap != "" && gap != null) localStorage.setItem("mv_gap", gap)
        if (atr != "" && atr != null) localStorage.setItem("mv_atr", atr)
        if (mc != "" && mc != null) localStorage.setItem("mv_mc", mc)
        if (mp != "" && mp != null) localStorage.setItem("mv_mp", mp)
        else localStorage.removeItem("mv_mp")
        
        if (cp != "" && cp != null) localStorage.setItem("mv_cp", cp)
        if (md != "" && md != null) localStorage.setItem("mv_md", md)

        setOpen(false);
        if (props.close != null) props.close(false)
        if (props.refresh != null) props.refresh()
    };


    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    設定
                </DialogTitle>
                {/* <Divider></Divider> */}

                <DialogContent sx={{ p: 3 }}>
                    <Typography fontWeight={"bold"} variant='body1' sx={{ mt: 0 }}>買入條件</Typography>
                    <Stack spacing={3} direction="column" sx={{ mt: 2 }}>

                        <TextField
                            size="medium"
                            id="A"
                            select
                            label="類型"
                            value={cp}
                            onChange={event => setCP(event.target.value)}
                            helperText="用什麼去計算動能排名，預設：全部股票+ETF"
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField size="medium" label="動能天數" fullWidth
                            value={md}
                            onChange={event => setMD(event.target.value)}
                            helperText="用近幾日去計算動能大小，預設：90"
                            inputProps={{  }}
                        ></TextField>

                        <TextField size="medium" label="市值" fullWidth
                            value={mc}
                            onChange={event => setMC(event.target.value)}
                            helperText="市值排名前N名，預設：200"
                            inputProps={{  }}
                        ></TextField>

                        <TextField size="medium" label="均線" fullWidth
                            value={avg}
                            onChange={event => setAvg(event.target.value)}
                            helperText="股價要大於N日均線，預設：100"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">日</InputAdornment>,
                            }}
                            inputProps={{  }}
                        ></TextField>
                        <TextField size="medium" label="跳空" fullWidth
                            value={gap}
                            onChange={event => setGap(event.target.value)}
                            helperText="2日跳空不能超過N%。預設：15%"
                            inputProps={{  }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        ></TextField>
                        <TextField size="medium" label="最大價格" fullWidth
                            value={mp}
                            onChange={event => setMP(event.target.value)}
                            helperText=""
                            inputProps={{  }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">元</InputAdornment>,
                            }}
                        ></TextField>

                    </Stack>


                    <Typography fontWeight={"bold"} variant='body1' sx={{ mt: 5 }}>資金配置</Typography>
                    <Stack spacing={3} direction="column" sx={{ mt: 2 }}>
                        <TextField
                            value={capital}
                            onChange={event => setCapital(event.target.value)}
                            inputProps={{  }}
                            size="medium" label="總資金" fullWidth InputProps={{
                                endAdornment: <InputAdornment position="end">元</InputAdornment>,
                            }}></TextField>
                        <TextField
                            value={risk}
                            onChange={event => setRisk(event.target.value)}
                            size="medium" label="風險因子" fullWidth
                            helperText="數值越大，購買單一股票張數越多，分散越少。預設：0.1%"
                            inputProps={{  }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}></TextField>

                        <TextField size="medium" label="ATR" fullWidth
                            value={atr}
                            onChange={event => setATR(event.target.value)}
                            helperText="平均真實區間。預設20日"
                            inputProps={{  }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">日</InputAdornment>,
                            }}
                        ></TextField>

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ width: 80 }} onClick={handleClose}>取消</Button>
                    <Button sx={{ width: 80 }} onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

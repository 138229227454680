import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { CheckBox } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { purple, deepOrange, green, red, blueGrey, blue, pink, grey } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getId, numberWithCommas } from '../util/ID'
import { putInpQueryParam } from '../util/ID'
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Height } from '../util/ScreenHeight'
import BuyDialog from './BuyDialog'
import AlertD from '../util/AlertD'
import RuleDialog from './RuleDialog'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import DetailKChart from '../rec/DetailKChart'
import StockHistoryDialog from './StockHistoryDialog';
import StrageDialog from '../strage/StrageDialog';
import SearchCompany from '../love/SearchCompany';
import { Adsense } from '@ctrl/react-adsense';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // fontWeight:"bold",
        fontSize: 18,
        whiteSpace: "nowrap"
        // backgroundColor: "#313f57",
        // color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        whiteSpace: "nowrap",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function InsTable(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(1)
    const [result, setResult] = React.useState(null);
    const [loading, setLoading] = React.useState(false)

    const [buyNum, setBuyNum] = React.useState(0)

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [openSetting3, setOpenSetting3] = React.useState(false);
    const [openSetting4, setOpenSetting4] = React.useState(false);
    const [sid, setSid] = React.useState(null);
    const [sid1, setSID1] = React.useState(null);

    const [row, setRow] = React.useState(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const openDetail2 = (id) => {
        setSid(id)
        setOpenSetting2(true)
    }

    const openDetail3 = (id) => {
        setSid(id)
        setOpenSetting3(true)
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getAvg = () => {
        return localStorage.getItem("mv_avg") != null ? localStorage.getItem("mv_avg") : 100
    }

    const getATR = () => {
        return localStorage.getItem("mv_atr") != null ? localStorage.getItem("mv_atr") : 20
    }

    const getCapital = () => {
        return localStorage.getItem("mv_capital") != null ? localStorage.getItem("mv_capital") : 300000
    }

    const getMC = () => {
        return localStorage.getItem("mv_mc") != null ? localStorage.getItem("mv_mc") : 200
    }

    const getCp = () => {
        return localStorage.getItem("mv_cp") != null ? localStorage.getItem("mv_cp") : 0
    }

    const getMD = () => {
        return localStorage.getItem("mv_md") != null ? localStorage.getItem("mv_md") : 90
    }

    const fetchInitData = () => {
        setPage(1)
        fetchDataInter(1)
    }


    const fetchData = () => {
        fetchDataInter(page)
    }

    const fetchDataInter = (pv) => {
        setLoading(true)

        let risk = localStorage.getItem("mv_risk") != null ? localStorage.getItem("mv_risk") : 0.1
        let gap = localStorage.getItem("mv_gap") != null ? localStorage.getItem("mv_gap") : 15
        let rules = localStorage.getItem('mv_search_rules') != null ? JSON.parse(localStorage.getItem('mv_search_rules')) : []

        let param = {
            page: pv,
            capital: getCapital(),
            risk: parseFloat(risk) / 100,
            gap: gap,
            avg: getAvg(),
            atr_days: getATR(),
            mc: getMC(),
            sn: getId(),
            cp: getCp(),
            md: getMD(),
            rules: rules,
            inp: {}
        }

        if (localStorage.getItem("move_lic") != null) {
            param['lic'] = localStorage.getItem("move_lic")
        }

        if (localStorage.getItem("mv_mp") != null) {
            param['mp'] = localStorage.getItem("mv_mp")
        }

        if (sid1 != null) {
            param['sid'] = sid1
        }

        param = putInpQueryParam(param)

        fetch("/move/v1/app/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null) {
                        if (result.data != null && result.data.length > 0) {
                            setTotal(result.total_page)
                            setResult(result.data)
                            setBuyNum(result.count)
                        } else {
                            setTotal(0)
                            setResult([])
                        }
                    }
                }
            );
    }

    useEffect(() => {
        fetchData()
    }, [page]);

    useEffect(() => {
        fetchInitData()
    }, [sid1]);


    const getColor = (row) => {
        if (row != null && row.price != null) {
            if (row.price.i > 0) return red[400]
            else if (row.price.i < 0) return green[400]
            else return null
        } else return null
    }

    const getInv = (row) => {
        if (row != null && row.price != null) {
            let pp = ((row.price.i * 100) / (row.price.p - row.price.i)).toFixed(1)

            if (row.price.i > 0) return "▲" + row.price.i + ' (' + pp + '%)'
            else if (row.price.i < 0) return "▼" + row.price.i + ' (' + pp + '%)'
            else return row.price.i + '元' + ' (' + pp + '%)'
        } else return '▲0元'
    }

    const getColor1 = (row) => {
        if (row != null && row.price != null) {
            if (row.price.i > 0) return red[400]
            else if (row.price.i < 0) return green[400]
            else return null
        } else return null
    }

    const openDetail = (row) => {
        setRow(row)
        setOpenSetting(true)
    }

    const getRank = () => {
        return localStorage.getItem("mv_rank") != null ? localStorage.getItem("mv_rank") : 30
    }

    const isRankErr = (row) => {
        return (row.rank > getRank())
    }

    const getNoAd = () => {
        return localStorage.getItem('noad') != null ? localStorage.getItem('noad') : "0"
    }

    return (
        <>
            {openSetting ? <BuyDialog row={row} refresh={fetchData} open={openSetting} close={setOpenSetting}></BuyDialog> : null}
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting1 ? <RuleDialog refresh={fetchInitData} open={openSetting1} close={setOpenSetting1}></RuleDialog> : null}
            {openSetting2 && <DetailKChart sid={sid} open={openSetting2} close={setOpenSetting2} />}
            {openSetting3 && <StockHistoryDialog sid={sid} open={openSetting3} close={setOpenSetting3} />}
            {openSetting4 && <StrageDialog open={openSetting4} close={setOpenSetting4} refresh={fetchData} />}


            <Box sx={{ p: 0, width: "100%", overflowY: 'hidden' }}>
                {/* 投資組合：{buyNum}檔 */}
                <TableContainer component={Paper} sx={{ position: 'absolute', top: Height.header, bottom: props.ismobile || fullScreen ? 100 : 55, mt: 1.5 }}>
                    <Typography fullWidth sx={{ mt: 3, textAlign: "center" }} variant="h4">動能排序表</Typography>

                    {getNoAd() === "0" && <Adsense
                        client="ca-pub-8613274553211553"
                        slot="1589318149"
                        style={{ display: 'block' }}
                        layout="in-article"
                        format="fluid"
                    />}
                    <Stack direction={"row"} spacing={0.5} sx={{ m: 0.5 }}>
                        <Box sx={{ width: "100%" }}>
                            <SearchCompany variant="filled" size="small" setSID={setSID1} />
                        </Box>
                        {/* 總資金：{numberWithCommas(getCapital())}元，買進條件：市值前{getMC()}名... */}
                        <Button disableElevation sx={{
                            color: "#000000", bgcolor: grey[300], ':hover': {
                                bgcolor: grey[300],
                                color: '#000000',
                            }
                        }} variant='contained' fullWidth onClick={e => setOpenSetting1(true)} >動能條件</Button>
                        <Button disableElevation sx={{
                            color: "#000000", bgcolor: grey[300], ':hover': {
                                bgcolor: grey[300],
                                color: '#000000',
                            }
                        }} variant='contained' fullWidth onClick={e => setOpenSetting4(true)}>選股條件</Button>
                    </Stack>

                    <Table sx={{ minWidth: 700 }} stickyHeader size="small">
                        <TableHead>
                            <TableRow >
                                <StyledTableCell align="left">動能</StyledTableCell>
                                <StyledTableCell>股票</StyledTableCell>
                                <StyledTableCell >類股</StyledTableCell>
                                <StyledTableCell align="right">斜率</StyledTableCell>
                                <StyledTableCell align="right">{getAvg()}日均</StyledTableCell>
                                <StyledTableCell align="right">跳空</StyledTableCell>
                                <StyledTableCell align="right">ATR</StyledTableCell>
                                <StyledTableCell align="right">買入股數</StyledTableCell>
                                <StyledTableCell align="right">所需資金</StyledTableCell>
                                {/* <StyledTableCell align="right">占總資金</StyledTableCell> */}
                                <StyledTableCell align="center">動作</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result != null && result.map((row) => (
                                <StyledTableRow key={row.rank}>
                                    <StyledTableCell align="center" sx={{}} onClick={e => openDetail3(row.company.stock_no)} >
                                        <Avatar sx={{ color: "#FFFFFF", bgcolor: isRankErr(row) ? green[400] : red[400], fontSize: 16, width: 48, height: 48 }} variant="rounded">
                                            {row.rank}
                                        </Avatar>
                                    </StyledTableCell>
                                    <StyledTableCell onClick={e => openDetail2(row.company.stock_no)} >
                                        {row.company.short_name} {row.company.stock_no}

                                        <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                                            <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{row.price != null ? row.price.p : "-"}</Typography>
                                            <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{getInv(row)}</Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{}} >
                                        {row.company.inductry}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {row.moving}
                                    </StyledTableCell>

                                    <StyledTableCell align="right">
                                        <Stack>
                                            {row.avg100}元
                                            {row.avg_err && <Chip size="small" label="未符合" sx={{ bgcolor: green[700], color: "#FFFFFF" }} />}
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Stack>
                                            {row.gap}%
                                            {row.gap_err && <Chip size="small" label="未符合" sx={{ bgcolor: green[700], color: "#FFFFFF" }} />}
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {row.atr}元
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{numberWithCommas(row.num)}股</StyledTableCell>
                                    <StyledTableCell align="right">{numberWithCommas(row.cost)}元
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box sx={{ width: '100%', mr: 1 }}>
                                                <LinearProgress value={row.risk} variant="determinate" {...props} />
                                            </Box>
                                            <Box sx={{ minWidth: 35 }}>
                                                <Typography variant="caption" color="text.secondary">{row.risk}%</Typography>
                                            </Box>
                                        </Box>
                                    </StyledTableCell>
                                    {/* <StyledTableCell align="right">{row.risk}%</StyledTableCell> */}
                                    <StyledTableCell align="center">
                                        {row.avg_err || row.gap_err || isRankErr(row) ?
                                            <Button onClick={e => openDetail(row)} disableElevation variant='contained' sx={{
                                                color: "#000000", bgcolor: grey[400], width: 120, ':hover': {
                                                    bgcolor: grey[300],
                                                    color: '#000000',
                                                }
                                            }}>不建議買</Button> :
                                            <Button onClick={e => openDetail(row)} disableElevation variant='contained' sx={{
                                                bgcolor: grey[700], width: 120, ':hover': {
                                                    bgcolor: grey[700], // theme.palette.primary.main
                                                    color: 'white',
                                                }
                                            }}>買進</Button>}


                                        {/* <Button onClick={e => openDetail(row)} disableElevation variant='contained' color="inherit" sx={{ width: 100, bgcolor: (row.avg_err || row.gap_err) ? green[200] : grey[300], color: "#000000" }} >{(row.avg_err || row.gap_err) ? "不買進" : "買進"} </Button> */}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {result != null && result.length > 0 && < Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
            </Box>}
        </>
    );
}

import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { CheckBox } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { purple, deepOrange, green, red, blueGrey, blue, pink, grey } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getId, numberWithCommas } from '../util/ID'
import { putInpQueryParam } from '../util/ID'
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Height } from '../util/ScreenHeight'
import SellDialog from './SellDialog'
import AlertD from '../util/AlertD'
import BuyRuleDialog from './BuyRuleDialog'
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import BuyDialog1 from './BuyDialog1'
import DetailKChart from '../rec/DetailKChart'
import { Adsense } from '@ctrl/react-adsense';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // fontWeight:"bold",
        fontSize: 18,
        whiteSpace: "nowrap"
        // backgroundColor: "#313f57",
        // color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        whiteSpace: "nowrap",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function BuyListTable(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(1)
    const [result, setResult] = React.useState(null);
    const [loading, setLoading] = React.useState(false)

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [row, setRow] = React.useState(null);
    const [openSetting3, setOpenSetting3] = React.useState(false);
    const [sid, setSid] = React.useState(null);

    const [age, setAge] = React.useState("");

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getAvg = () => {
        return localStorage.getItem("mv_avg") != null ? localStorage.getItem("mv_avg") : 100
    }

    const getATR = () => {
        return localStorage.getItem("mv_atr") != null ? localStorage.getItem("mv_atr") : 20
    }

    const getCapital = () => {
        return localStorage.getItem("mv_capital") != null ? localStorage.getItem("mv_capital") : 300000
    }

    const getMC = () => {
        return localStorage.getItem("mv_mc") != null ? localStorage.getItem("mv_mc") : 200
    }

    const getRank = () => {
        return localStorage.getItem("mv_rank") != null ? localStorage.getItem("mv_rank") : 30
    }

    const getCp = () => {
        return localStorage.getItem("mv_cp") != null ? localStorage.getItem("mv_cp") : 0
    }

    const getMD = () => {
        return localStorage.getItem("mv_md") != null ? localStorage.getItem("mv_md") : 90
    }

    const fetchData = () => {
        setLoading(true)

        let risk = localStorage.getItem("mv_risk") != null ? localStorage.getItem("mv_risk") : 0.1
        let gap = localStorage.getItem("mv_gap") != null ? localStorage.getItem("mv_gap") : 15

        let param = {
            page: page,
            rank: getRank(),
            capital: getCapital(),
            risk: parseFloat(risk) / 100,
            gap: gap,
            avg: getAvg(),
            atr_days: getATR(),
            mc: getMC(),
            sn: getId(),
            cp : getCp(),
            md: getMD()
        }

        if (localStorage.getItem("move_lic") != null) {
            param['lic'] = localStorage.getItem("move_lic")
        }

        param = putInpQueryParam(param)

        fetch("/move/v1/buy/list", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null) {
                        if (result.l != null && result.l.length > 0) {
                            // setTotal(result.total_page)
                            setTotal(0)
                            setResult(result.l)
                            // setBuyNum(result.count)
                        } else {
                            setTotal(0)
                            setResult([])
                        }
                    }
                }
            );
    }

    useEffect(() => {
        fetchData()
    }, [page]);

    const getColor = (row) => {
        if (row != null && row.price != null) {
            if (row.price.i > 0) return red[400]
            else if (row.price.i < 0) return green[400]
            else return null
        } else return null
    }

    const getInv = (row) => {
        if (row != null && row.price != null) {
            let pp = ((row.price.i * 100) / (row.price.p - row.price.i)).toFixed(1)

            if (row.price.i > 0) return "▲" + row.price.i + ' (' + pp + '%)'
            else if (row.price.i < 0) return "▼" + row.price.i + ' (' + pp + '%)'
            else return row.price.i + '元' + ' (' + pp + '%)'
        } else return '▲0元'
    }

    const getColor1 = (value) => {
        if (value > 0) return red[400]
        else if (value < 0) return green[400]
        else return null
    }

    const openDetail = (row) => {
        setRow(row)
        setOpenSetting(true)
    }

    const roi = (bprice, nprice) => {
        return (((nprice / bprice) - 1) * 100).toFixed(2)
    }

    const isSell = (row) => {
        return row.aft == null || row.aft.avg_err || row.aft.gap_err || row.aft.rank > getRank()
    }

    const isRankErr = (row) => {
        return (row.aft == null || row.aft.rank > getRank())
    }

    const isRankErr1 = (row) => {
        return (row.ori == null || row.ori.rank > getRank())
    }

    const openDetail3 = (id) => {
        setSid(id)
        setOpenSetting3(true)
    }

    const getNoAd = () => {
        return localStorage.getItem('noad') != null ? localStorage.getItem('noad') : "0"
    }
    
    return (
        <>
            {openSetting ? <SellDialog row={row} refresh={fetchData} open={openSetting} close={setOpenSetting}></SellDialog> : null}
            {openSetting1 ? <BuyRuleDialog row={row} refresh={fetchData} open={openSetting1} close={setOpenSetting1}></BuyRuleDialog> : null}
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting2 ? <BuyDialog1 row={row} refresh={fetchData} open={openSetting2} close={setOpenSetting2}></BuyDialog1> : null}
            {openSetting3 && <DetailKChart sid={sid} open={openSetting3} close={setOpenSetting3} />}


            <Box sx={{ p: 0, width: "100%", overflowY: 'hidden' }}>

                <TableContainer component={Paper} sx={{ position: 'absolute', top: Height.header, bottom: props.ismobile || fullScreen ? 100 : 55, mt: 1.5 }}>
                    <Typography sx={{ mt: 3, textAlign: "center" }} variant="h4">持有庫存
                        <IconButton onClick={e => setOpenSetting2(true)}>
                            <AddIcon />
                        </IconButton>
                    </Typography>

                    {getNoAd() === "0" && <Adsense
                        client="ca-pub-8613274553211553"
                        slot="1589318149"
                        style={{ display: 'block' }}
                        layout="in-article"
                        format="fluid"
                    />}

                    <Button fullWidth onClick={e => setOpenSetting1(true)} >賣出條件：現在排名超過{getRank()}名...</Button>


                    <Table sx={{ minWidth: 700 }} stickyHeader size="small">
                        <TableHead>
                            <TableRow >
                                <StyledTableCell align="left">日期</StyledTableCell>
                                <StyledTableCell align="center">現在</StyledTableCell>
                                <StyledTableCell align="left">原始</StyledTableCell>
                                <StyledTableCell>股票</StyledTableCell>
                                <StyledTableCell >類股</StyledTableCell>

                                <StyledTableCell align="right">報酬率</StyledTableCell>

                                <StyledTableCell align="right">買進價格</StyledTableCell>
                                <StyledTableCell align="right">買進數量</StyledTableCell>

                                <StyledTableCell align="right">{getAvg()}日均</StyledTableCell>
                                <StyledTableCell align="right">跳空</StyledTableCell>
                                <StyledTableCell align="center">動作</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result != null && result.map((row) => {
                                return row.company != null && <StyledTableRow key={row.id}>
                                    <StyledTableCell align="left">
                                        {row.bymd}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{}} >
                                        <Stack direction={"row"} spacing={0.2}>
                                            <Avatar sx={{ color: "#FFFFFF", bgcolor: isRankErr(row) ? green[500] : red[400], fontSize: 16, width: 48, height: 48 }} variant="rounded">
                                                {row.aft != null ? row.aft.rank : "-"}
                                            </Avatar>
                                        </Stack>
                                    </StyledTableCell>

                                    <StyledTableCell align="center" sx={{}} >
                                        <Avatar sx={{ color: "#FFFFFF", bgcolor: isRankErr1(row) ? green[500] : red[400], fontSize: 16, width: 48, height: 48 }} variant="rounded">
                                            {row.ori != null ? row.ori.rank : "-"}
                                        </Avatar>
                                    </StyledTableCell>

                                    <StyledTableCell onClick={e => openDetail3(row.company.stock_no)}>
                                        <div>{row.company.short_name} {row.company.stock_no}</div>

                                        <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }}>
                                            <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{row.price != null ? row.price.p : "-"}</Typography>
                                            <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv(row)}</Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell >
                                        {row.company.inductry}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Typography sx={{ fontSize: 15, whiteSpace: "nowrap" }} color={getColor1(roi(row.bprice, row.price.p))}>{roi(row.bprice, row.price.p)}%</Typography>

                                    </StyledTableCell>

                                    <StyledTableCell align="right">
                                        {row.bprice}元
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {row.num}股
                                    </StyledTableCell>

                                    <StyledTableCell align="right">
                                        <Stack>
                                            {row.aft != null ? row.aft.avg100 + "元" : "-"}
                                            {row.aft != null && row.aft.avg_err && <Chip size="small" label="未符合" sx={{ bgcolor: green[700], color: "#FFFFFF" }} />}
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Stack>
                                            {row.aft != null ? row.aft.gap + "%" : "-"}
                                            {row.aft != null && row.aft.gap_err && <Chip size="small" label="未符合" sx={{ bgcolor: green[700], color: "#FFFFFF" }} />}
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {isSell(row) ?
                                            <Button onClick={e => openDetail(row)} disableElevation variant='contained' sx={{
                                                color: "#000000", bgcolor: grey[400], width: 120, ':hover': {
                                                    bgcolor: grey[300], // theme.palette.primary.main
                                                    color: '#000000',
                                                }
                                            }}>賣出</Button> :
                                            <Button onClick={e => openDetail(row)} disableElevation variant='contained' sx={{
                                                bgcolor: grey[700], width: 120, ':hover': {
                                                    bgcolor: grey[700], // theme.palette.primary.main
                                                    color: 'white',
                                                }
                                            }}>繼續持有</Button>}

                                        {/* <Button onClick={e => openDetail(row)} disableElevation variant='contained' color="inherit" sx={{ width: 100, bgcolor: isSell(row) ? green[300] : grey[300], color: "#000000" }} >{isSell(row) ? "賣出" : "繼續持有"} </Button> */}
                                    </StyledTableCell>
                                </StyledTableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {result != null && result.length > 0 && < Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
            </Box>}
        </>
    );
}

import React, { useEffect, useRef } from 'react';
import RecItem from './RecItem'
import { Height } from '../util/ScreenHeight'
import RecSelector from './RecSelector'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { getId } from '../util/ID'

import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import RateDetail from './RateDetail'
import DetailChart from './DetailChart'
import SellTable from './SellTable'
import RestTable from './RestTable'
import DetailTable from './DetailTable'


export default function BackTestContainer(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(100)

    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [tp, setTp] = React.useState(0)
    const [rules, setRules] = React.useState(null)
    const [openSetting, setOpenSetting] = React.useState(false);
    const [stock, setStock] = React.useState(null);

    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false)
    const [openSetting3, setOpenSetting3] = React.useState(false); 
    const [openSetting4, setOpenSetting4] = React.useState(false); 

    const [id, setId] = React.useState(null);
    const [sid, setSid] = React.useState(null);
    const [row, setRow] = React.useState(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const fetchData = () => {
        let param = {
        }

        if (localStorage.getItem("move_lic") != null) {
            param['lic'] = localStorage.getItem("move_lic")
        }

        fetch("/move/v1/backtest/list", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        if (result.data != null && result.data.length > 0) {
                            setTotal(result.total)
                            setResult(result.data)
                        } else {
                            setTotal(0)
                            setResult([])
                        }
                    }
                }
            );
    }

    React.useEffect(() => {
        const timer = setInterval(() => {
            fetchData()
        }, 5000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    React.useEffect(() => {
        fetchData()
    }, []);

    const updateLove = (sid) => {
        if (sid != null && result != null) {
            let nresult = result.map(r => {
                if (r.stock === sid) {
                    r['islv'] = !r.islv
                    return r
                } else return r
            })
            setResult(nresult)
        }
    }

    const openDetail = (stock) => {
        setStock(stock)
        setOpenSetting(true)
    }

    const openDetail1 = (id, sid) => {
        setId(id)
        setSid(sid)
        setOpenSetting1(true)
    }

    const openDetail2= (id) => {
        setId(id)
        setOpenSetting2(true)
    }

    const openDetail3= (id) => {
        setId(id)
        setOpenSetting3(true)
    }

    const openDetail4= (row) => {
        setRow(row)
        setOpenSetting4(true)
    }

    const getItem = (row) => {
        return <RecItem openDetail4={openDetail4} openDetail3={openDetail3} openDetail2={openDetail2} fetchData={fetchData} openDetail1={openDetail1} openDetail={openDetail} updateLove={updateLove} tp={tp} key={row.stock} row={row} menu={menu} />
    }


    return (
        <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
            {openSetting && <RateDetail openDetail1={openDetail1} stock={stock} open={openSetting} close={setOpenSetting}></RateDetail>}
            {openSetting1 && <DetailChart id={id} sid={sid} open={openSetting1} close={setOpenSetting1} />}
            {openSetting2 && <SellTable id={id} open={openSetting2} close={setOpenSetting2} />}
            {openSetting3 && <RestTable id={id} open={openSetting3} close={setOpenSetting3} />}
            {openSetting4 && <DetailTable row={row} open={openSetting4} close={setOpenSetting4} />}

            <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
                <RecSelector setMenu={setMenu} refresh={fetchData} enableSetting={true} />

                <Grid container spacing={0} sx={{ p: 0.5, width: '100%', bgcolor: 'background.paper', position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 100 : 55, overflowY: 'scroll' }}>
                    {result != null && result.map(row => {
                        return <Grid key={row.id} item xs={12} sm={6} md={4} lg={3}>
                            {getItem(row)}
                        </Grid>
                    })}
                </Grid>

                {result != null && result.length > 0 && < Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                    <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
                </Box>}
            </Box >
        </Box >
    );
}

import React from 'react'
import { BrowserRouter as Router, Route, HashRouter, Routes } from 'react-router-dom'
import CHome from './chome/CHome'
import CGHome from './googlehome/CHome'
import IOSHome from './ioshome/CHome'
import ExpectHome from './expect/chome/CHome'


export default props => (
  <HashRouter>
    <Routes>
      <Route exact path='/' element={<CHome />} />
      <Route exact path='/gool' element={<CGHome />} />
      <Route exact path='/open' element={ <CHome/> } />  
      <Route exact path='/ios' element={<IOSHome />} />
      <Route exact path='/expect/home' element={<ExpectHome pathname={"home"}/>} />
      <Route exact path='/expect/ghome' element={<ExpectHome pathname={"google"}/>} />
      <Route exact path='/expect/ioshome' element={<ExpectHome pathname={"ios"}/>} />                       
    </Routes>
  </HashRouter>
)